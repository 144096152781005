import { isInExperienceEditor } from '@cfed/theming/assets/js/helpers';

function EmbeddedVideo() {
    return {
        async init() {
            if (isInExperienceEditor()) {
                return;
            }
            const embeddedVideos = document.querySelectorAll('.embedded-video');
            if (embeddedVideos) {
                const { default: renderReactPlayer } = await import(
                    'react-player/dist/ReactPlayer.standalone.es6'
                );

                embeddedVideos.forEach((el) => renderReactPlayer(el, {
                    playing: false,
                    controls: true,
                    className: 'video-wrapper',
                    height: '',
                    width: '',
                    url: el.dataset.url,
                }));
            }
        },
    };
}

export default EmbeddedVideo;
