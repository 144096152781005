import { isInExperienceEditor } from '@cfed/theming/js/helpers';
import { SEARCH_RESULTS_LOADED } from '../lib/events';
import { searchResultsEmptyEvent } from '../lib/site-search-events';

export * from './site-search-templates';

export function initSearchResultsEmptyAdapter() {
    document.body.addEventListener(SEARCH_RESULTS_LOADED, (evt) => {
        const { resultData } = evt.detail;
        const isEmpty = resultData?.results?.length === 0;
        if (isEmpty) {
            evt.target.dispatchEvent(searchResultsEmptyEvent());
        }
    });
}

export default class SearchResultsCustomElement extends HTMLElement {
    templateManager;

    async connectedCallback() {
        if (isInExperienceEditor()) return;

        const searchResults = (await import('./site-search-results')).default;

        searchResults(this.templateManager).init(this);
    }
}
