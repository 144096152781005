<script>
    import { getContext } from 'svelte';

    export let item;
    export let enableHeading = false;
    export let padding = '';

    let handleClick = getContext('handleClick');

    const className =
        'display-5 list-group-item d-flex justify-content-between align-items-center no-icon';

    $: href = item?.url;
    $: title = item?.title;
</script>

{#if href}
    <a
        {href}
        class={className}
        style:font-weight="normal"
        style:color="#006891"
        style:padding-left={padding}
    >
        {title}
    </a>
{:else if enableHeading}
    <span style:color="#0f3051" class={className} style:padding-left={padding}>
        {title}
    </span>
{:else}
    <button
        class={className}
        on:click={() => handleClick(item)}
        style:padding-left={padding}
        style:color="#006891"
    >
        {title}
        {#if item.items.length > 0}
            <i class="fas fa-angle-right"></i>
        {/if}
    </button>
{/if}
