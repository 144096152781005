import { isInExperienceEditor } from '@cfed/theming/js/helpers';

export default class SearchBoxCustomElement extends HTMLElement {
    async connectedCallback() {
        if (isInExperienceEditor()) return;

        const searchBox = (await import('./site-search-box')).default;

        searchBox().init(this);
    }
}
