import jQuery from 'jquery';
import jsrender from 'jsrender/jsrender';

export const formatTime = (value) =>
    new Intl.DateTimeFormat('en-us', {
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
        timeZone: 'America/New_York',
    }).format(Date.parse(value));

export const JSRenderMixin = (base) =>
    class extends base {
        constructor(...args) {
            super(...args);

            jsrender(jQuery);
        }

        templateByName(templateName) {
            return jQuery.templates[templateName];
        }

        async getTemplates(templateNames) {
            const templates = await Promise.all(
                templateNames.map(async (templateName) => [
                    templateName,
                    await this.templateLoader(templateName),
                ])
            );

            templates.forEach(([name, value]) =>
                jQuery?.templates(name, value)
            );
        }

        async render(props) {
            await this.getTemplates(this.templateNames);

            return (el) => {
                el.innerHTML = this.templateByName('resultList')?.render(
                    props,
                    {
                        formatDate: this.formatDate,
                        formatTime,
                    }
                );
            };
        }
    };
