import { isInExperienceEditor } from '@cfed/theming/js/helpers';

// equivalent of http://api.jquery.com/offset/
function offset(el) {
    const { top, left } = el.getBoundingClientRect();
    const { clientTop, clientLeft } = document.documentElement;
    const { pageYOffset, pageXOffset } = window;

    return {
        top: top + pageYOffset - clientTop,
        left: left + pageXOffset - clientLeft,
    };
}

function cfAccordion() {
    const api = {};

    api.getDelayedRenderingCount = function getDelayedRenderingCount(
        component
    ) {
        return component.querySelectorAll('[data-delayed-rendering=true]')
            .length;
    };

    api.getRenderedCount = function getRenderedCount(component) {
        return component.querySelectorAll('.rendered').length;
    };

    api.initInstance = function initInstance(component) {
        const delayedCount = api.getDelayedRenderingCount(component);

        if (delayedCount > 0) {
            // local function to loop until initialized
            api.waitForDelayedRenderings(component, delayedCount);
        } else {
            api.cleanupItems(component);
        }
    };

    api.waitForDelayedRenderings = function waitForDelayedRenderings(
        component,
        delayedCount
    ) {
        let counter = 0;
        const looper = setInterval(() => {
            counter += 1;
            if (
                api.getRenderedCount(component) === delayedCount ||
                counter >= 10
            ) {
                api.cleanupItems(component);
                clearInterval(looper);
            }
        }, 500);
    };

    api.cleanupItems = function cleanupItems(component) {
        component.querySelectorAll('ul.items > li').forEach((section) => {
            const sectionContentArea =
                section.querySelector('div.item__content');

            // case: no component is set in placeholder or the data source is missing
            if (sectionContentArea.children.length === 0) {
                section.remove();
                return; // continue to next iteration in the loop
            }

            // case: check all component that are added to the section. If all
            // output empty text or no elements then hide section
            let hideSection = true;
            sectionContentArea
                .querySelectorAll('.component-content')
                .forEach((content) => {
                    if (
                        content.children.length > 0 ||
                        content.textContent.trim().length > 0
                    ) {
                        hideSection = false;
                        return false;
                    }
                    return true;
                });

            if (hideSection) {
                section.remove();
            }
        });
    };

    api.isActiveItemInViewport = function isActiveItemInViewport(
        activeTitle,
        scrollHeight
    ) {
        if (activeTitle === null) {
            return false;
        }

        const activeTitleHeight = activeTitle.offsetHeight;
        const elementTop = scrollHeight;
        const elementBottom = scrollHeight + activeTitleHeight;
        const viewportTop = document.documentElement.scrollTop;
        const viewportBottom = viewportTop + window.innerHeight;

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    api.calculateScrollHeight = function calculateScrollHeight(component) {
        const scrollHeight = offset(component).top;
        let extraHeight = 0;
        const activeTitle = component.querySelector('.active_title');
        const activeTitleIndex = Number(
            activeTitle.getAttribute('data-item-index')
        );
        const items = component.querySelectorAll('.item__title');
        items.forEach((item, index) => {
            if (index < activeTitleIndex - 1 && !isNaN(activeTitleIndex)) {
                extraHeight += item.offsetHeight;
            }
        });

        return scrollHeight + extraHeight;
    };

    api.handleScroll = function handleScroll(component) {
        const activeTitle = component.querySelector('.active_title');
        const scrollHeight = api.calculateScrollHeight(component);
        const activeTitleHeight = activeTitle.offsetHeight;

        // Need to make sure active title is visible
        if (!api.isActiveItemInViewport(activeTitle, scrollHeight)) {
            document.documentElement.scrollTo({
                left: document.documentElement.scrollLeft,
                top: scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    api.init = function init() {
        if (isInExperienceEditor()) {
            return;
        }

        document
            .querySelectorAll('.cf-accordion:not(.initialized)')
            .forEach((el) => {
                api.initInstance(el);
                el.classList.add('initialized');
            });

        document
            .querySelectorAll('.cf-accordion .item__title')
            .forEach((itemtitle) => {
                itemtitle.addEventListener('click', api.titleClick);
            });
    };

    api.titleClick = function titleClicks(ev) {
        const thisAccordion = this.closest('.cf-accordion');
        thisAccordion
            .querySelectorAll('.item__title')
            .forEach((el) => el.classList.remove('active_title'));
        this.classList.add('active_title');
        api.handleScroll(thisAccordion);
    };

    return api;
}

export default cfAccordion;
