import Backbone from 'backbone';
import SearchBox from './search-box';
import SearchFilters from './search-filters';
import { SearchTaxonomy } from './search-taxonomy';
import SearchRelatedTopics from './search-related-topics';
import SearchFacets from './search-facets';
import { SearchSpelling } from './search-spelling';
import { initSearchResultsEmptyAdapter } from './search-results';

export { default as SearchResultsCustomElement } from './search-results';

export default async function initSearch(SearchResults) {
    Backbone.$ = globalThis.jQuery;

    customElements.define('search-facets', SearchFacets);
    customElements.define('search-results', SearchResults);
    customElements.define('search-box', SearchBox);
    customElements.define('search-filters', SearchFilters);
    customElements.define('search-taxonomy', SearchTaxonomy);
    customElements.define('search-related-topics', SearchRelatedTopics);
    customElements.define('search-spelling', SearchSpelling);

    initSearchResultsEmptyAdapter();
}
