import Backbone from 'backbone';
import { SEARCH_RESULTS_LOADED, SEARCH_RESULTS_EMPTY } from './events';

export const searchResultsLoadedEvent = (detail) =>
    new CustomEvent(SEARCH_RESULTS_LOADED, { detail, bubbles: true });

export const searchResultsEmptyEvent = () =>
    new CustomEvent(SEARCH_RESULTS_EMPTY, { bubbles: true });

export default Backbone.Events;
