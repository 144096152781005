export const query = 'q';
export const sorting = 'o';
export const offset = 'e';
export const pageSize = 'p';
export const paging = 'pg';
export const dateFrom = 'df';
export const dateTo = 'dt';
export const signature = 'sig';
export const filterPrefix = 'f-';
export const buildContexualUrls = 'bcu';
export const pagePathname = 'ppn';
export const facets = 'fp';
export const showNullSearchText = 'snt';
export const showBestBetsText = 'sbb';
export const showImageText = 'si';
export const showPdfPagesText = 'sp';
export const hideAuthorsText = 'au';
export const taxonomoyId = 'tcid';
export const filteredListText = 'fl';
