import { isInExperienceEditor } from '@cfed/theming/assets/js/helpers';

export default class SearchFiltersCustomElement extends HTMLElement {
    async connectedCallback() {
        if (isInExperienceEditor()) return;

        const searchFilters = (await import('./site-search-filters')).default;

        searchFilters().init(this);
    }
}
