import _camelCase from 'lodash/camelCase';

export const toCamelCase = (obj) =>
    Object.entries(obj).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [_camelCase(key)]: value,
        }),
        {}
    );
