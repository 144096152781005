<script>
    import { setContext } from 'svelte';
    import MenuItem from './components/MenuItem.svelte';

    export let menu;

    let selected;

    const handleBack = () => (selected = undefined);

    const handleClick = (item) => (selected = item);

    setContext('handleClick', handleClick);

    $: current = selected ? selected : menu;
</script>

<div class="list-group list-group-flush">
    {#if selected}
        <button
            style:font-weight="normal"
            style:color="#006891"
            class="display-5 list-group-item d-flex align-items-center"
            aria-expanded="true" 
            on:click={handleBack} 
        >
            <i class="fas fa-angle-left"></i>
            <span>&nbsp; Main Menu</span>
        </button>
        <span
            style:color="#617b14"
            class="display-4 list-group-item d-flex align-items-center"
        >
            {current.title}
        </span>
        {#each current.items as item}
            {#if item.title}
                <MenuItem {item} enableHeading />
            {/if}
            {#if item?.items?.length > 0}
                {#each item.items as i}
                    <MenuItem item={i} padding="3rem" />
                {/each}
            {/if}
        {/each}
    {:else}
        {#key current}
            {#each current as item}
                <MenuItem {item} />
            {/each}
        {/key}
    {/if}
</div>
