import Backbone from 'backbone';
import { SEARCH_RESULTS_LOADED, SEARCH_RESULTS_EMPTY, RESULTS_LOADING } from './events';

export const searchResultsLoadingEvent = (detail) =>
    new CustomEvent(RESULTS_LOADING, { detail, bubbles: true });

export const searchResultsLoadedEvent = (detail) =>
    new CustomEvent(SEARCH_RESULTS_LOADED, { detail, bubbles: true });

export const searchResultsEmptyEvent = () =>
    new CustomEvent(SEARCH_RESULTS_EMPTY, { bubbles: true });

export default Backbone.Events;
