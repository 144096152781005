/* globals hbspt */
import { customImport } from '@cfed/theming/assets/js/helpers'; 

class cfHubSpotForm extends HTMLElement {
    // This could be passed through from data source in Sitecore, if we thought it would change
    portalId = '8397508';

    scriptUrl = 'https://js.hsforms.net/forms/v2.js?pre=1';

    formId;

    connectedCallback() {
        this.attachShadow({ mode: 'open' });
        this.formId = this.getAttribute('formid');

        this.loadForm();
    }

    async loadForm() {
        await customImport(this.scriptUrl, [this.shadowRoot, document.body]);
        const { hbspt } = globalThis;

        hbspt?.forms.create({
            region: 'na1',
            portalId: this.portalId,
            formId: this.formId,
            target: `#form-${this.formId}`,
        });
    }
}

export default cfHubSpotForm;
