import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import initGroups from '@cfed/groups';
import initGeneralContent from '@cfed/general-content';
import initSearch from '@cfed/site-search';
import initIcons from '@cfed/theming/js/iconography';
import FSBSearchResults from './search';
import initNavigation from './navigation';
import '@fontsource-variable/archivo';
import '@fontsource-variable/archivo/wdth.css';
import '@fontsource-variable/archivo/wdth-italic.css';

import '../scss/fedsmallbusiness.scss';

globalThis.jQuery = jQuery;

const iconLoader = async () => (await import('./icons')).default;

initNavigation();
initGroups();
initGeneralContent('fedsmallbusiness');
initSearch(FSBSearchResults);
initIcons(iconLoader);

if (globalThis?.SitecoreSettings) {
    import('@cfed/theming/js/EnvironmentContextBanner');
}
