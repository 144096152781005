import { JSRenderMixin } from '@cfed/site-search/mixins';

export const FSBTemplateService = JSRenderMixin(
    class {
        templateNames = [
            'resultList',
            'sortSelector',
            'pageSelector',
            'resultItem',
            'generalPageItem',
            'articleItem',
            'articleMeta',
            'speechMeta',
            'eventMeta',
            'taxonomy',
        ];

        formatDate(value) {
            const myDate = Date.parse(value),
                parts = new Intl.DateTimeFormat('en-us', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    timeZone: 'America/New_York',
                }).formatToParts(myDate);
            return (
                parts[0].value + ' ' + parts[2].value + ', ' + parts[4].value
            );
        }

        templateLoader = async (templateName) =>
            (await import(`./templates/${templateName}.tmpl?raw`)).default;
    }
);
